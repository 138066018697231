import React from 'react'
import { Container, Flex, Box, Card, css } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ListItem from '@solid-ui-components/ListItem'
import FlexImage from '@solid-ui-components/FlexImage'
import FlexContent from '@solid-ui-components/FlexContent'
import FlexOverlapFade from '@solid-ui-components/FlexOverlapFade'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import { textContent } from '../textContent'
import ContentButtons from '../../../../solid-ui-components/src/ContentButtons/ContentButtons'
import Sticky from 'react-sticky-el'

const linksTermsOfUse = [
  {
    "text": "Informações Gerais",
    "type": "ANCHOR",
    "link": "#termos-informacoes",
    "variant": "links.normal"
  },
  {
    "text": "1. Definições",
    "type": "ANCHOR",
    "link": "#termos-definicoes",
    "variant": "links.normal"
  },
  {
    "text": "2. Restrições De Idade Para Cadastro",
    "type": "ANCHOR",
    "link": "#restricoesdeidade",
    "variant": "links.normal"
  },
  {
    "text": "3. Manutenção, Alteração E Exclusão Do Cadastro",
    "type": "ANCHOR",
    "link": "#manutencaoalteracao",
    "variant": "links.normal"
  },
  {
    "text": "4. Da Navegação",
    "type": "ANCHOR",
    "link": "#danavegacao",
    "variant": "links.normal"
  },
  {
    "text": "5. Da Gestão do Aplicativo",
    "type": "ANCHOR",
    "link": "#dagestaodoaplicativo",
    "variant": "links.normal"
  },
  {
    "text": "6. Nossas Responsabilidades",
    "type": "ANCHOR",
    "link": "#nossasresponsabilidades",
    "variant": "links.normal"
  },
  {
    "text": "7. Uso Do Aplicativo",
    "type": "ANCHOR",
    "link": "#usodoaplicativo",
    "variant": "links.normal"
  },
  {
    "text": "8. Links Externos",
    "type": "ANCHOR",
    "link": "#linksexternos",
    "variant": "links.normal"
  },
  {
    "text": "9. Dos Direitos De Propriedade Intelectual Sobre Programas De Computador E Sobre Nosso Conteúdo",
    "type": "ANCHOR",
    "link": "#dosdireitosdepropriedade",
    "variant": "links.normal"
  },
  {
    "text": "10. Dos Produtos E Dos Serviços",
    "type": "ANCHOR",
    "link": "#dosprodutoseservicos",
    "variant": "links.normal"
  },
  {
    "text": "11. Dos Preços dos Produtos E Dos Serviços",
    "type": "ANCHOR",
    "link": "#precosprodutoseservicos",
    "variant": "links.normal"
  },
  {
    "text": "12. Do Pagamento",
    "type": "ANCHOR",
    "link": "#dopagamento",
    "variant": "links.normal"
  },
  {
    "text": "13. Da Entrega Dos Produtos",
    "type": "ANCHOR",
    "link": "#daentregadosprodutos",
    "variant": "links.normal"
  },
  {
    "text": "14. Da Prestação Dos Serviços",
    "type": "ANCHOR",
    "link": "#daprestacaodosservicos",
    "variant": "links.normal"
  },
  {
    "text": "15. Do Direito De Arrependimento",
    "type": "ANCHOR",
    "link": "#doarrependimento",
    "variant": "links.normal"
  },
  {
    "text": "16. Das Trocas E Devoluções",
    "type": "ANCHOR",
    "link": "#dastrocasedevolucoes",
    "variant": "links.normal"
  },
  {
    "text": "17. Da Proteção De Dados Pessoais",
    "type": "ANCHOR",
    "link": "#daprotecaodedados",
    "variant": "links.normal"
  },
  {
    "text": "18. Do Serviço De Atendimento Ao Usuário",
    "type": "ANCHOR",
    "link": "#servicodeatendimento",
    "variant": "links.normal"
  },
  {
    "text": "19. Das Sanções",
    "type": "ANCHOR",
    "link": "#dassancoes",
    "variant": "links.normal"
  },
  {
    "text": "20. Das Alterações",
    "type": "ANCHOR",
    "link": "#dasalteracoes",
    "variant": "links.normal"
  },
  {
    "text": "21. Do Direito Aplicável E Do Foro",
    "type": "ANCHOR",
    "link": "#dodireitoaplicavel",
    "variant": "links.normal"
  },
]


const UsePolicyContent = () => {
  return (
    <Container>
      <Flex
        sx={{
          alignItems: [`space-between`],
          justifyContend: 'center',
          flexDirection: [
            `column-reverse`,
            `row`
          ],
          mx: [null, null, null, null]
        }}
      >
        <Box sx={{ textAlign: ['center', 'left'] }}>
          <ContentText content={textContent} />
        </Box>
        <Container sx={{
          width: '150%',
          fontSize: '14px',
          padding: '0',
          display: [`none`, `block`]
        }}>
          <Sticky boundaryElement=".termsofusecontent" hideOnBoundaryHit={false} stickyStyle={{ paddingTop: '100px', paddingBottom: '50px' }}>
            <Box sx={{ borderLeft: '2px solid orange', paddingLeft: '15px' }}>
              <ContentButtons content={linksTermsOfUse} style={{ margin: '0', padding: '0' }} />
            </Box>
          </Sticky>
        </Container>
      </Flex>
      <FlexOverlapFade direction={'rtl'} />
    </Container>
  )
}

export default UsePolicyContent
