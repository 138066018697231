export const textContent = [
	{ text: 'TERMOS E CONDIÇÕES GERAIS DE USO E DE COMPRA E VENDA DO APLICATIVO "JUIT Rimor"', variant: 'h4', mb: 4 },
	{ text: 'Estes  termos  e  condições  (daqui  em  diante  referidos  apenas  como  "Termos")  se  aplicam  à utilização de Nosso Aplicativo, "JUIT Rimor", por você.', variant: 'p' },
	{ text: 'Nosso  Aplicativo  é  mantido  pela  pessoa  jurídica  JUIT  Tecnologias  Para  o  Direito  LTDA("JUIT"),   devidamente   registrada   sob   o   CNPJ   n.   32.552.081/0001-29,   e-mail: contato@juit.io, com sede em:', variant: 'p' },
	{ text: 'R. Ribeiro de Barros, 385. Bairro Lapa. São Paulo - SP. CEP 05027-020', variant: 'p' },
	{ text: 'Estes  Termos  se  aplicam  a  todos  aqueles  que  se  cadastrarem  em  Nosso Aplicativo,  de modo que quem desejar concluir seu cadastro deve ler cuidadosamente e concordar com as regras previstas neste documento.', variant: 'h5', mt: 4 },
	{ text: 'Aqueles  que  não  compreenderem  ou  que  não  aceitarem  as  normas  previstas  nestes  Termos não poderão se cadastrar em Nosso Aplicativo e, consequentemente, não poderão acessar nem utilizar os recursos restritos a usuários cadastrados.', variant: 'p', id: 'termos-informacoes', mt: 4 },
	{ text: '1. DEFINIÇÕES', variant: 'h4', id: 'termos-definicoes', mt: 4 },
	{ text: '"Nós",  "Nosso"  ou  "Nossos"  refere-se  a  JUIT  Tecnologias  Para  o  Direito  LTDA  ("JUIT"), pessoa jurídica que mantém e é responsável pelo Aplicativo.', variant: 'p', mt: 4 },
	{ text: '"Aplicativo" é o aplicativo "JUIT Rimor", incluindo todas as suas páginas e recursos.', variant: 'p' },
	{ text: '"Código de Defesa do Consumidor" ou "CDC" é Lei Federal n. 8.078, de 11 de setembro de 1990.', variant: 'p' },
	{ text: '"Partes" se refere tanto ao Usuário quanto a Nós, indistintamente.', variant: 'p' },
	{ text: '"Produtos"  se  refere  aos  seguintes  produtos  que  são  vendidos  diretamente  por  Nós  por  meio de Nosso Aplicativo e que podem ser adquiridos pelos Usuários:', variant: 'p' },
	{ text: '- Assinatura do JUIT Rimor', variant: 'small' },
	{ text: '- Consumo de dados estruturados via API', variant: 'small' },
	{ text: '"Serviços" se refere aos seguintes serviços prestados diretamente por Nós por meio de Nosso Aplicativo:', variant: 'p' },
	{ text: '- Personalização do software para atender necessidades e regras de negócio específicas', variant: 'small' },
	{ text: '"Nosso   Conteúdo"   se   refere   a   toda   e   qualquer   informação   ou   dado   publicado   ou disponibilizado diretamente por Nós no Aplicativo, incluindo, dentre outros possíveis, textos, imagens, vídeos e áudios.', variant: 'small' },
	{ text: '"Conteúdo  de  Terceiros"  se  refere  a  toda  e  qualquer  informação  ou  dado  publicado  ou disponibilizado  no  Aplicativo  por  terceiros,  Usuários  ou  não,  incluindo,  dentre  outros possíveis, textos, imagens, vídeos, áudios e links.', variant: 'p' },
	{ text: '"Usuário" é a pessoa que navega ou que de qualquer outra forma utiliza Nosso Aplicativo.', variant: 'p' },
	{ text: '2. RESTRIÇÕES DE IDADE PARA CADASTRO', variant: 'h4', mt: 4, id: 'restricoesdeidade' },
	{ text: 'Somente poderão se cadastrar em Nosso Aplicativo pessoas maiores de 16 anos.', variant: 'p', mt: 4 },
	{ text: 'Os  maiores  de  16  anos  e  menores  de  18  anos  não  emancipados  somente  poderão  utilizar Nosso Aplicativo se estiverem devidamente assistidos por seus pais ou representantes legais.', variant: 'p' },
	{ text: '3. MANUTENÇÃO, ALTERAÇÃO E EXCLUSÃO DO CADASTRO', variant: 'h4', id: 'manutencaoalteracao', mt: 4 },
	{ text: 'Cada  Usuário  poderá  manter  apenas  uma  conta  junto  ao  Aplicativo.  Contas  duplicadas, quando  identificadas,  serão  automaticamente  desativadas  por  Nós,  sem  prejuízo  de  demais penalidades cabíveis.', variant: 'p', mt: 4 },
	{ text: 'Para  que  o  cadastro  seja  concluído,  o  Usuário  deverá  fornecer  integralmente  os  dados requeridos.  Todas  as  informações  fornecidas  pelo  Usuário  devem  ser  precisas,  verdadeiras  e atualizadas. Em qualquer caso, o Usuário poderá responder, em âmbito cível e criminal, pela veracidade, exatidão e autenticidade dos dados informados.', variant: 'p' },
	{ text: 'O  Usuário  deverá  fornecer  um  endereço  de  e-mail  válido,  através  do  qual  todos  os  contatos serão   realizados.   Todas   as   comunicações   enviadas   para   o   referido   endereço   serão consideradas  lidas  pelo  usuário,  que  se  compromete,  assim,  a  consultar  regularmente  as mensagens recebidas e a respondê-las, se necessário, em prazo razoável.', variant: 'p' },
	{ text: 'Após a confirmação de seu cadastro, o usuário possuirá um login e uma senha pessoais, que deverão  ser  por  ele  utilizados  para  o  acesso  à  sua  conta  no  Aplicativo.  Estes  dados  não deverão ser informados pelo Usuário a terceiros, sendo de sua inteira responsabilidade o uso que  deles  seja  feito.  O  usuário  deverá  Nos  comunicar  imediatamente  caso  suspeite  da ocorrência de quaisquer atividades suspeitas ou inesperadas em sua conta.', variant: 'p' },
	{ text: 'Não  será  permitido  ceder,  vender,  alugar  ou  transferir,  de  qualquer  forma,  uma  conta  de Usuário, que é pessoal e intransferível.', variant: 'p' },
	{ text: 'Sempre  que  os  dados  de  seu  cadastro  estiverem  desatualizados,  o  Usuário  deverá  corrigi-los por meio das ferramentas que disponibilizamos para que isto seja feito.', variant: 'p' },
	{ text: 'O  Usuário  que  descumprir  quaisquer  das  normas  contidas  no  presente  instrumento  terá  sua conta  suspensa  e  será  notificado  para  se  justificar.  Caso  não  haja  a  apresentação  de justificativa  dentro  do  prazo  assinalado  ou  caso  a  justificativa  não  seja  aceita,  seu  cadastro será excluído, sendo-lhe vedado realizar nova inscrição no Aplicativo.', variant: 'p' },
	{ text: 'O   usuário   poderá,   a   qualquer   tempo   e   sem   necessidade   de   justificação,   requerer   o cancelamento  de  seu  cadastro  junto  ao  Aplicativo.  O  seu  descadastramento  será  realizado  o mais rapidamente possível, desde que não sejam verificados débitos em aberto.', variant: 'p' },
	{ text: 'Seja  em  caso  de  pedido  de  cancelamento  de  cadastro,  seja  em  caso  de  deleção  por descumprimento  das  normas  aqui  previstas,  os  dados  pessoais  do  Usuário  serão  tratados  em conformidade  com  nossa  Política  de  Privacidade,  sendo  possível  que  todos  ou  alguns  dados sejam  mantidos  mesmo  após  a  exclusão  da  conta,  independentemente  do  consentimento  do titular dos dados pessoais.', variant: 'p' },
	{ text: '4. DA NAVEGAÇÃO', variant: 'h4', id: 'danavegacao', mt: 4 },
	{ text: 'Nos  comprometemos  a  utilizar  todas  as  soluções  técnicas  à  nossa  disposição  para  que  o Aplicativo possa ser acessado 24 (vinte e quatro) horas por dia, todos os dias. Entretanto, Nós poderemos, a qualquer momento, interromper, limitar ou suspender o acesso ao Aplicativo ou a algumas de suas páginas ou recursos, a fim de realizar atualizações, modificações, correções ou qualquer outra ação que consideremos necessária para garantir seu bom funcionamento.', variant: 'p', mt: 4 },
	{ text: '5. DA GESTÃO DO APLICATIVO', variant: 'h4', id: 'dagestaodoaplicativo', mt: 4 },
	{ text: 'Para a boa gestão, Nós poderemos, a qualquer momento:', variant: 'p', mt: 4 },
	{ text: 'a)  suspender,  interromper  ou  limitar  o  acesso  a  todo  ou  a  parte  do  Aplicativo  a  uma categoria específica ou a todos os Usuários;', variant: 'small' },
	{ text: 'b)  remover  toda  informação  que  possa  perturbar  o  funcionamento  do  Aplicativo  ou  que esteja em conflito com normas de Direito brasileiro ou de Direito internacional;', variant: 'small' },
	{ text: 'c) suspender o Aplicativo, a fim de realizar atualizações e modificações.', variant: 'small' },
	{ text: '6. NOSSAS RESPONSABILIDADES', variant: 'h4', id: 'nossasresponsabilidades', mt: 4 },
	{ text: 'Embora adotemos tudo o que está ao Nosso alcance para manter Nosso Aplicativo em pleno e em   correto   funcionamento,   Nós   nos   responsabilizaremos   pelos   defeitos   ou   vícios eventualmente encontrados no programa de computador que compõe Nosso Aplicativo e que tenham sido por Nós causados, desde que haja comprovado dano.', variant: 'p', mt: 4 },
	{ text: 'Defeitos ou problemas surgidos no âmbito ou em decorrência dos sistemas ou equipamentos utilizados  pelo  Usuário  para  acessar  e  utilizar  Nosso  Aplicativo  não  serão  de  Nossa responsabilidade.', variant: 'p' },
	{ text: 'Nos responsabilizamos, ainda, nos limites e nos termos da lei, pelo Nosso Conteúdo. Nós não assumimos, porém, qualquer responsabilidade por Conteúdo de Terceiros.', variant: 'p' },
	{ text: 'Nós  não  nos  responsabilizamos  pelos  usos  que  os  Usuários  fizerem  de  Nosso  Aplicativo, cabendo-lhes responder pessoal e exclusivamente por suas próprias ações.', variant: 'p' },
	{ text: 'Nós  não  Nos  responsabilizaremos  por  eventuais  danos  decorrentes  de  caso  fortuito,  força maior ou por aqueles que forem decorrentes das ações de terceiros.', variant: 'p' },
	{ text: 'Nós  somos  responsáveis  pelos  Nossos  Produtos  e  pelos  Nossos  Serviços  comercializados  no âmbito  do  Aplicativo  e  nos  comprometemos  a  entregá-los  ou  a  prestá-los,  conforme  for  o caso,   de   acordo   com   as   condições   estabelecidas   durante   a   contratação.   Não   Nos responsabilizamos, porém, em situações que fujam ao nosso controle, como nas situações em que  o  Usuário  contratante  nos  repassar  informações  falsas  ou  incorretas  ou  em  que  houver caso   fortuito   ou   força   maior   que   prejudique   ou   que   impeça   que   cumpramos   nossos compromissos a tempo e modo.', variant: 'p' },
	{ text: '7. USO DO APLICATIVO', variant: 'h4', id: 'usodoaplicativo', mt: 4 },
	{ text: 'O   Usuário   deverá   utilizar   as   funcionalidades   do   Aplicativo   para   finalidades   que   não contrariem  a  lei,  a  moral  ou  os  bons  costumes.  Em  específico,  deverá  se  limitar  a  utilizá-las em conformidade com os objetivos para as quais foram criadas.', variant: 'p', mt: 4 },
	{ text: 'O  Usuário  não  poderá  utilizar  crawlers,  robôs  ou  quaisquer  programas  de  computador, algoritmos, equipamentos ou metodologias automatizadas para acessar, copiar, ler, monitorar, navegar, preencher formulários ou publicar conteúdo no Aplicativo.', variant: 'p' },
	{ text: 'O Usuário não poderá utilizar quaisquer meios ou ferramentas automatizados ou manuais para acessar   qualquer   conteúdo   ou   informação   que   não   lhe   tenha   sido   intencionalmente disponibilizada por Nós, incluindo as informações constantes de seções de Nosso Aplicativo e as  que  estejam  armazenadas  ou  que  trafeguem  no  âmbito  da  infraestrutura  de  Tecnologia  da Informação utilizada por Nós, que inclui equipamentos, redes e servidores.', variant: 'p' },
	{ text: 'O Usuário não poderá violar nem testar as vulnerabilidades dos mecanismos de segurança do Aplicativo ou da infraestrutura de Tecnologia da Informação utilizada por Nós.', variant: 'p' },
	{ text: 'O  Usuário  não  poderá  utilizar  quaisquer  dos  conteúdos  ou  das  informações  disponibilizadas em Nosso Aplicativo para fins de identificar outros Usuários ou outras pessoas, a menos que o conteúdo ou informação seja licitamente divulgado com este objetivo.', variant: 'p' },
	{ text: 'O  Usuário  não  poderá  sobrecarregar  a  infraestrutura  de  Tecnologia  da  Informação  que mantém o Aplicativo com um número excessivo ou desarrazoado de requisições.', variant: 'p' },
	{ text: 'O Usuário não poderá esconder sua identidade nem a origem de sua conexão, nem poderá se passar  por  outra  pessoa.  Neste  sentido,  além  de  outras  condutas  possíveis,  o  Usuário  não poderá  utilizar  quaisquer  ferramentas  ou  mecanismos  para  manipular  ou  para  mascarar  a origem  de  qualquer  mensagem  enviada  a  Nós  ou  à  Nossa  infraestrutura  de  Tecnologia  da Informação por meio de Nosso Aplicativo ou de qualquer de suas funcionalidades.', variant: 'p' },
	{ text: 'O  Usuário  cadastrado  será  responsável  pelo  uso  que  for  feito  de  suas  contas  de  usuário, devendo zelar para que suas credenciais de acesso (login e senha) não sejam compartilhados com terceiros.', variant: 'p' },
	{ text: 'O  Usuário  tem  a  responsabilidade  de  garantir  a  segurança  dos  equipamentos  eletrônicos  e sistemas que utilizar para acessar o Aplicativo.', variant: 'p' },
	{ text: 'O Usuário será responsável por qualquer conteúdo ou informação que eventualmente publicar em Nosso Aplicativo.', variant: 'p' },
	{ text: 'Não  será  autorizada  a  inclusão  de  páginas  que  divulguem  quaisquer  tipos  de  informações ilícitas, violentas, polêmicas, pornográficas, xenofóbicas, discriminatórias ou ofensivas.', variant: 'p' },
	{ text: '8. LINKS EXTERNOS', variant: 'h4', id: 'linksexternos', mt: 4 },
	{ text: 'Nosso Aplicativo pode conter links externos que redirecionam o Usuário para outras páginas da internet sobre as quais Nós não exercemos controle.', variant: 'p', mt: 4 },
	{ text: 'Apesar  das  verificações  prévias  e  regulares  que  realizamos,  Nos  isentamos  de  qualquer responsabilidade  sobre  o  conteúdo  encontrado  nas  páginas  e  aplicações  que  possam  ser acessadas a partir destes links.', variant: 'p' },
	{ text: '9.  DOS  DIREITOS  DE  PROPRIEDADE  INTELECTUAL  SOBRE  PROGRAMAS  DE COMPUTADOR E SOBRE NOSSO CONTEÚDO', variant: 'h4', id: 'dosdireitosdepropriedade', mt: 4 },
	{ text: 'Os programas de computador que compõem o Aplicativo e o Nosso Conteúdo disponibilizado no  Aplicativo  são  de  Nossa  propriedade,  a  menos  que  façamos  menção  expressa  em  sentido diverso.', variant: 'p', mt: 4 },
	{ text: 'Nossos  programas  de  computador  e  o  Nosso  Conteúdo  que  forem  protegidos  por  direitos autorais  não  poderão  ser,  integral  ou  parcialmente,  copiados,  reproduzidos,  representados, adaptados ou de qualquer forma alterados, por qualquer meio e para qualquer finalidade, sem Nossa  autorização  prévia,  expressa  e  por  escrito.  Em  caso  de  violações  a  Nossos  direitos  de propriedade  intelectual,  Nós  nos  resguardamos  o  direito  de  adotar  todas  as  providências judiciais e extrajudiciais cabíveis.', variant: 'p' },
	{ text: 'O  acesso  ao  Aplicativo  não  gera  para  o  Usuário  qualquer  direito  de  propriedade  intelectual relativo a elementos do Aplicativo.', variant: 'p' },
	{ text: '10. DOS PRODUTOS E DOS SERVIÇOS', variant: 'h4', id: 'dosprodutoseservicos', mt: 4 },
	{ text: 'Por  intermédio  do  Aplicativo,  Nós  fornecemos  ao  cliente  um  catálogo  ou  uma  loja  online apresentando  com  exatidão  os  Produtos  e  Serviços  vendidos.  Os  Produtos  e  Serviços  estão descritos   e   apresentados   com   o   maior   grau   de   precisão   possível,   acompanhados   de informações   corretas,   claras,   precisas,   ostensivas   e   em   língua   portuguesa   sobre   suas características,  qualidades,  quantidade,  composição,  preço,  garantia,  prazos  de  validade  e origem,  entre  outros  dados,  bem  como  sobre  os  eventuais  riscos  que  apresentam  à  saúde  e segurança do Usuário que os adquirir.', variant: 'p', mt: 4 },
	{ text: 'Os Produtos são colocados à venda até o limite do estoque disponível.', variant: 'p' },
	{ text: 'Os preços e as taxas referentes à venda dos Produtos e Serviços estão precisadas no catálogo ou na loja online.', variant: 'p' },
	{ text: 'Antes de finalizar a compra de determinado Produto ou Serviço, o Usuário deverá se informar sobre as suas especificações e sobre a sua destinação. Em se tratando de Produto ou Serviço adquirido  no  âmbito  de  ofertas,  o  Usuário  deverá  também  observar  suas  condições  de aplicação.', variant: 'p' },
	{ text: 'As  ofertas  serão  disponibilizadas  no  Aplicativo  de  acordo  com  a  Nossa  conveniência.  Elas poderão ser concluídas antes do prazo previsto, se o estoque for finalizado e não for possível restabelecê-lo com Nossos fornecedores.', variant: 'p' },
	{ text: '11. DOS PREÇOS DOS PRODUTOS E DOS SERVIÇOS', variant: 'h4', id: 'precosprodutoseservicos', mt: 4 },
	{ text: 'O  vendedor  se  reserva  o  direito  de  modificar  os  preços  dos  Produtos  e  Serviços  a  qualquer momento, publicando-os no Aplicativo.', variant: 'p', mt: 4 },
	{ text: 'Serão aplicadas as tarifas em vigor no momento do pedido, sob reserva de disponibilidade de Produtos e Serviços nesta data.', variant: 'p' },
	{ text: 'Os  preços  serão  indicados  em  reais  e  não  incluirão  as  taxas  de  entrega,  as  quais  virão especificadas à parte e serão informadas antes da realização do pedido.', variant: 'p' },
	{ text: 'O montante total do pedido, incluindo todas as taxas, será indicado antes da validação final do pedido.', variant: 'p' },
	{ text: '12. DO PAGAMENTO', variant: 'h4', id: 'dopagamento', mt: 4 },
	{ text: 'O pagamento do pedido deverá ser realizado à vista ou à prazo, através dos seguintes meios:', variant: 'p', mt: 4 },
	{ text: 'cartão  de  crédito  (uso  pessoal),  boleto  bancário  (uso  pessoal  apenas  anuidade,  e  uso coletivo  mensalidades  e  anuidades),  transferência  bancária  (uso  coletivo)  e  pix  (uso coletivo).', variant: 'small' },
	{ text: '13. DA ENTREGA DOS PRODUTOS', variant: 'h4', id: 'daentregadosprodutos', mt: 4 },
	{ text: 'O  prazo  de  entrega  dos  Produtos  adquiridos  no  Aplicativo  será  informado  no  momento  do pedido,  em  dias  úteis.  O  tempo  para  a  entrega  é  calculado  conforme  o  estoque,  a  região,  o processo de emissão da nota fiscal e a preparação do pedido.', variant: 'p', mt: 4 },
	{ text: 'Após a finalização do pedido, pode não ser possível alterar a forma de pagamento, o endereço de entrega ou as condições de entrega do Produto, tais como prioridade ou adiantamento.', variant: 'p' },
	{ text: '14. DA PRESTAÇÃO DOS SERVIÇOS', variant: 'h4', id: 'daprestacaodosservicos', mt: 4 },
	{ text: 'Os  Serviços  serão  prestados  por  Nós  em  conformidade  com  as  especificações,  condições  e demais informações disponíveis em Nosso Aplicativo.', variant: 'p', mt: 4 },
	{ text: '15. DO DIREITO DE ARREPENDIMENTO', variant: 'h4', id: 'doarrependimento', mt: 4 },
	{ text: 'Dentro do prazo de 7 (sete) dias, contados da data do recebimento do produto adquirido ou da contratação do serviço, o cliente poderá desistir da compra e solicitar a devolução dos valores pagos, sem necessidade de apresentação de quaisquer justificativas.', variant: 'p', mt: 4 },
	{ text: 'Neste caso, ele deverá fazer retornar ao vendedor o produto ou serviço adquirido, nas mesmas condições em que o recebeu.', variant: 'p' },
	{ text: '16. DAS TROCAS E DEVOLUÇÕES', variant: 'h4', id: 'dastrocasedevolucoes', mt: 4 },
	{ text: 'A política de trocas e de devoluções do Aplicativo será regida conforme o Código de Defesa do Consumidor (Lei Federal n. 8.078, de 11 de setembro de 1990).', variant: 'p', mt: 4 },
	{ text: 'Afora  a  hipótese  de  arrependimento  do  cliente,  a  troca  ou  a  devolução  dos  produtos  ou  dos serviços  adquiridos  apenas  será  realizada  mediante  verificação  de  vícios  de  qualidade  ou quantidade que os tornem impróprios ou inadequados ao consumo a que se destinam ou que lhes diminuam o valor. Também poderão ser trocados ou devolvidos os produtos ou serviços que  apresentem  disparidade  com  as  indicações  constantes  do  recipiente,  da  embalagem, rotulagem ou mensagem publicitária, respeitadas as variações decorrentes de sua natureza.', variant: 'p' },
	{ text: 'O Usuário deverá entrar em contato conosco, por meio de Nosso serviço de atendimento, tão logo  constate  o  vício  no  produto  ou  serviço  adquirido.  Se,  no  prazo  máximo  de  30  (trinta) dias, não for possível resolver o vício ou, independente deste prazo, a substituição das partes viciadas  puder  comprometer  a  qualidade  ou  características  do  produto  ou  serviço,  diminuir-lhe  o  valor  ou  se  tratar  de  produto  ou  serviço  essencial,  o  Usuário  poderá  optar  pela substituição  do  produto  por  outro  de  mesma  espécie  ou  pela  reexecução  do  serviço,  pela devolução da quantia paga ou pelo abatimento proporcional do preço.', variant: 'p' },
	{ text: '17. DA PROTEÇÃO DE DADOS PESSOAIS', variant: 'h4', id: 'daprotecaodedados', mt: 4 },
	{ text: 'Nós  tratamos  os  dados  pessoais  dos  Usuários  em  conformidade  com  a  Lei  Federal  n. 13.709/2018 (Lei Geral de Proteção de Dados Pessoais) e demais normas correlatas que Nos são aplicáveis. Mais informações sobre este assunto poderão ser consultadas em nossa Política de Privacidade, que pode ser acessada a partir do link: https://juit.io/politica-de-privacidade.', variant: 'p', mt: 4 },
	{ text: 'Na  referida  Política,  identificamos  os  dados  pessoais  que  tratamos  e  prestamos  informações sobre  como,  por  qual  motivo  e  com  qual  fundamento  legal  o  fazemos,  bem  como  sobre  a forma  pela  qual  os  Usuários  podem  exercer  seus  direitos  relacionados  à  proteção  de  seus dados pessoais e sobre como podem entrar em contato com nosso Encarregado de Proteção de Dados Pessoais.', variant: 'p' },
	{ text: 'Os   pedidos   de   alteração,   retificação   ou   exclusão   de   dados   pessoais   Nos   devem   ser direcionados em conformidade com o previsto naquela Política.', variant: 'p' },
	{ text: '18. DO SERVIÇO DE ATENDIMENTO AO USUÁRIO', variant: 'h4', id: 'servicodeatendimento', mt: 4 },
	{ text: 'Em  caso  de  dúvidas,  sugestões  ou  problemas  com  a  utilização  do  Aplicativo,  o  Usuário poderá  contatar  diretamente  Nosso  serviço  de  atendimento,  através  do  endereço  de  e-mail: contato@juit.io  ou  do  telefone:  (11)  95600-5848.  Estes  serviços  de  atendimento  ao  Usuário estarão disponíveis nos seguintes dias e horários:', variant: 'p', mt: 4 },
	{ text: 'de segunda à sexta-feira, das 9h às 18h (horário comercial)', variant: 'small' },
	{ text: 'O Usuário poderá, ainda, optar por enviar correspondência ao endereço da sede do Aplicativo, informado no início deste documento.', variant: 'p' },
	{ text: '19. DAS SANÇÕES', variant: 'h4', id: 'dassancoes', mt: 4 },
	{ text: 'Sem  prejuízo  das  demais  medidas  legais  cabíveis,  Nós  poderemos,  a  qualquer  momento, advertir, suspender ou cancelar a conta do Usuário:', variant: 'p', mt: 4 },
	{ text: 'a) que descumprir quaisquer dos dispositivos contidos no presente instrumento;', variant: 'small' },
	{ text: 'b) que descumprir os seus deveres de Usuário;', variant: 'small' },
	{ text: 'c) que praticar atos fraudulentos, ilegais ou imorais;', variant: 'small' },
	{ text: 'd) que fornecer qualquer informação incorreta;', variant: 'small' },
	{ text: 'e) cujo comportamento constitua ou possa vir a importar ofensa ou dano a terceiro ou ao próprio Aplicativo.', variant: 'small' },
	{ text: '20. DAS ALTERAÇÕES', variant: 'h4', id: 'dasalteracoes', mt: 4 },
	{ text: 'A  presente  versão  dos  termos  e  condições  gerais  de  uso  foi  atualizada  pela  última  vez  em: 19/07/2021.', variant: 'p', mt: 4 },
	{ text: 'Nós  nos  reservamos  o  direito  de  modificar,  a  qualquer  momento,  o  aplicativo  e  os  serviços, bem  como  as  presentes  normas,  especialmente  para  adaptá-las  às  evoluções  do  Aplicativo, seja  pela  disponibilização  de  novas  funcionalidades,  seja  pela  supressão  ou  modificação daquelas já existentes.', variant: 'p' },
	{ text: 'O Usuário será explicitamente notificado em caso de alteração do presente termo.', variant: 'p' },
	{ text: '21. DO DIREITO APLICÁVEL E DO FORO', variant: 'h4', id: 'dodireitoaplicavel', mt: 4 },
	{ text: 'Para   a   solução   das   controvérsias   decorrentes   do   presente   instrumento,   será   aplicado integralmente o Direito brasileiro.', variant: 'p', mt: 4 },
	{ text: 'Os eventuais litígios deverão ser apresentados no foro da comarca em que se encontra a sede do editor do aplicativo.', variant: 'p' },
	{ text: 'Seja bem-vindo(a)!', variant: 'p' },
	{ text: 'A equipe do aplicativo JUIT Rimor lhe deseja uma excelente navegação!', variant: 'p' },
]

export default textContent